
















































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import * as XLSX from 'xlsx';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    	Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
  },
})
export default class Groups extends Vue {
   windowWidth = window.innerWidth;

   activeTableID: any = null;

    dataLoadingBackend = false;

   activeWorkspaceID: any = null;

   deleteGroupPopup = false;

    friendlyLayerName = '';

   deleteGroupParams: any = {};

   selectedAttrbute: any = [];

   selectedColumn: any = '';

   foundWellsCount: any = 0;

   availableSearchOptions: any = [];

   get stylesForNextBtn() {
     if (this.stepNo === 0 && !this.fileExtractedData.length) {
       return false;
     } if (this.stepNo === 3) {
       return this.tableRowKeys.find((key) => key === 'Well');
     }
     return true;
   }

      newAttributePayload: any = [];

   stepNo: any = 0;

   newColumnName = '';

  headerText: any = ['Upload your assets', 'Organize your assets', 'Tag your assets', 'Match column labels to asset information'];

  nextButtonText: any = ['Continue to Organize', 'Continue to Organize', 'Continue to Match', 'Finalize Import'];

  newGroupName = '';

  showCreateGroupModal =false;

  searchDataLoading = false;

  matchedWellNames: any = [];

  searchResultWells: any =[]

    knowAttributesData: any = []

    get knowAttributes() {
      return this.knowAttributesData.filter((attr) => {
        //  console.log('test');
        if (this.attributeSearchQuery) {
          return attr.Name.toLowerCase().includes(this.attributeSearchQuery.toLowerCase());
        }
        return true;
      });
    }

  foundWellNames: any = [];

  availableWellNames = [];

  get attributeTypeOptions() {
      return [
      { text: 'Text Field', value: 'TEXT' },
      { text: 'Date', value: 'TIMESTAMP' },
      { text: 'Number', value: 'FLOAT' },
      { text: 'Checkboxes', value: 'FUNCTION' },
      { text: 'Images/Video', value: 'BLOB' },
      { text: 'PDF Procedure', value: 'BLOB' },
    ];
  }

  tempGroups = []

  searchTerms: any = [];

  fileExtractedData: any = [];

  totalNoWellRows: any = 0;

  searchTermsJson: any = [];

  dropdownLocation: any = [];

  knownAttrbutesList: any = [];

  tableRowKeys: any = [];

  didSelectDropdown(e, columnName) {
    // if (type == 'Status Changes' || type == 'Duplicate Tasq') {
    // console.log(e)

    const rect = e.srcElement.parentElement.getBoundingClientRect();
    this.dropdownLocation = [rect.x, rect.y + 25];

    // } else {
    // 	const rect = e.srcElement.getBoundingClientRect()
    // 	this.dropdownLocation = [rect.x - 13, rect.y + 60]
    // }
  }

  showAttributeTable: any = false;

  openAttributeTable(columnName) {
    this.showAttributeTable = true;
    this.selectedColumn = columnName;
  }

  selectAttribute(attribute) {
    this.selectedAttrbute = attribute;
    // console.log(this.selectedAttrbute);
    if (this.selectedColumn) {
      this.foundWellNames.map((row) => {
        // console.log(row);
        // console.log(this.selectedColumn);
        const value = row[this.selectedColumn];
        // console.log(value);
        delete row[this.selectedColumn];
        const key = `${this.selectedAttrbute.Name}:${this.selectedAttrbute.ID}`;
        row[key] = value;
      });

      this.tableRowKeys = this.tableRowKeys.map((key) => {
        if (key === this.selectedColumn) {
          // eslint-disable-next-line no-param-reassign
          key = `${this.selectedAttrbute.Name}:${this.selectedAttrbute.ID}`;
        }
        return key;
      });

      this.showAttributeTable = false;
    }
  }

  deleteTableColumn(data) {
    // console.log(data);
    this.foundWellNames = this.foundWellNames.map((row) => {
      if (row[data]) {
        delete row[data];
      }
      return row;
    });

    this.tableRowKeys = this.tableRowKeys.filter((key) => key !== data);
  }

  selectWellName() {
    if (this.selectedColumn) {
      this.foundWellNames.map((row) => {
        // console.log(row);
        // console.log(this.selectedColumn);
        const value = row[this.selectedColumn];
        // console.log(value);
        delete row[this.selectedColumn];
        const key = 'Well';
        row[key] = value;
      });
      // console.log(this.tableRowKeys);
      this.tableRowKeys = this.tableRowKeys.filter((k) => k !== this.selectedColumn);
      this.tableRowKeys.unshift('Well');
      this.showAttributeTable = false;
      this.parepareImportData(this.foundWellNames);
    }
  }

  	async didSelectDropdownOption() {
    if (this.newAttributePayload && this.newAttributePayload[0]) {
      const data = this.newAttributePayload[0];

      await metaDataModule.postKnownAttributes({ name: this.attributeSearchQuery, type: data.value });
      this.knowAttributesData = await metaDataModule.fetchKnownAttributes();
    }
    this.dropdownLocation = [];
  }

  hideDropdown() {
    this.dropdownLocation = [];
  }

  nextStep() {
    // if (this.stylesForNextBtn) {
    let wait = 1500;
    this.dataLoading = true;
    if (this.stepNo === 2) {
      wait = 5000;
      this.stepNo += 1;
    } else if (this.stepNo === 3) {
      this.stepNo = 0;
      this.finaliseImport();
      this.$emit('close-upload-dialog');
    } else {
      this.stepNo += 1;
    }

    setTimeout(() => {
      this.dataLoading = false;
    }, wait);
    // }
  }

  async previewFiles(evt) {
    // console.log(evt);
    this.dataLoading = true;
    const event: any = evt;
    const fileUpload = event.target.files[0];
    if (event.target && fileUpload && fileUpload.type !== 'text/csv') {
      const { files } = event.target; const
        f = files[0];

      const reader = new FileReader();
      reader.onload = (subEvent) => {
        if (subEvent.target) {
          // ts-ignore
          const resultData: any = subEvent.target.result;
          const data = new Uint8Array(resultData);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          /* DO SOMETHING WITH workbook HERE */
          // console.log(workbook);
          const worksheet = workbook.Sheets[sheetName];
          worksheet.A1 = {
            t: 's',
            v: 'WELL',
            r: 'WELL',
            h: 'WELL',
            w: 'WELL',
          };
          const result = (XLSX.utils.sheet_to_json(worksheet));
          // console.log(worksheet);
          // console.log(result);
          this.fileExtractedData = result;
          this.foundWellNames = result;
          // console.log(Object.keys(this.foundWellNames[0]));
          this.tableRowKeys = Object.keys(this.foundWellNames[0]);
          // this.parepareImportData(result);
        }
      };
      reader.readAsArrayBuffer(f);
    } else if (event.target && fileUpload) {
      // console.log(event.target.files);
      const file = event.target.files[0];
      // console.log(await file.text())
      let lines: any = '';
      let currentLine: any = '';
      let csv: any = '';
      let headers: any = '';
      const result: any = [];
      const reader: any = new FileReader();
      reader.readAsBinaryString(file);
      let index = 0;
      reader.onload = (evt) => {
        csv = reader.result;
        const d = { t: '"', of: ',', nf: '\t' };
        const regex = new RegExp(`(${d.t}|^)((\\d*${d.of})+)(${d.t}|\\d*$)`, 'gmi');
        csv.replace(regex, (a) => a.replace(new RegExp(d.of, 'gmi'), `${d.nf}`));
        lines = csv.split('\r' + '\n');
        // console.log(lines)
        headers = lines[0].split(',');
        // console.log(headers);
        if (headers.find((h) => h.toLowerCase().includes('well'))) {
          index = 1;
        }
        for (let i = index; i < lines.length; i++) {
          if (!lines[i]) { continue; }
          const obj = {};
          currentLine = lines[i];
          const re = /"/g;
          currentLine = re[Symbol.replace](currentLine, '--');
          // console.log(currentLine);
          currentLine = currentLine.split(',');

          // eslint-disable-next-line no-loop-func
          currentLine.forEach((d, i) => {
            if (d.includes('--') && currentLine[i + 1] && currentLine[i + 1].includes('--')) {
              currentLine[i] = `${d}${currentLine[i + 1]}`;
              currentLine[i] = currentLine[i].replace(/-/g, '');
              // console.log(currentLine)
              currentLine.splice(i + 1, 1);
            } else if (d.includes('--')) {
              currentLine[i] = currentLine[i].replace(/-/g, '');
            }
          });
          // console.log(currentLine)
          for (let j = 0; j < headers.length; j++) {
            if (j < 10) {
              let head = headers[j].trim();

              if (j === 0 && index === 1) {
                // head = 'Well';
              } else {
                head = j;
              }
              const value = currentLine[j].trim();
              // console.log(value)
              obj[head] = value;
            }
          }
          result.push(obj);
        }
        // console.log(result);
        this.fileExtractedData = result;
        this.foundWellNames = result;
        this.tableRowKeys = Object.keys(result[0]);
        // console.log(this.foundWellNames)
        // this.parepareImportData(result);
      };
    }

    setTimeout(() => {
      this.dataLoading = false;
    }, 5000);
  }

  parepareImportData(result) {
    if (result && result.length) {
      this.totalNoWellRows = result.length;
      const columnkeys: any = Object.keys(result[0]);
      // eslint-disable-next-line prefer-destructuring
      // firstColumnKey = firstColumnKey[0];
      //  ts-ignore

      // console.log(columnkeys);

      if (columnkeys && columnkeys.length) {
        const foundWells = result.filter((row) => {
          const value: string = row.Well;
          // ts-ignore

          return this.availableWellNames.find((name: any = '') => name.toLowerCase() === value.toLowerCase());
          //
        }).map((well) => {
          const value: string = well.Well;
          const matchName = (this.availableWellNames.filter((name: any = '') => name.toLowerCase() === value.toLowerCase()));
          if (matchName) {
            well.Well = matchName[0];
          }

          return well;
        });
        // this.foundWellNames = (foundWells);
        this.matchedWellNames = foundWells;
        // console.log(this.foundWellNames)
      }
    }
  }

  async finaliseImport() {
    const data = {
      rowData: this.matchedWellNames,
      columnNames: Object.keys(this.matchedWellNames[0]),
      tags: this.selectedTags,
    };
    let well: any = null;

    this.matchedWellNames.forEach(async (wellDetail) => {
      Object.keys(wellDetail).forEach(async (key, index) => {
        // console.log(well);
        // console.log(key.split(':'));
        if (index === 0) {
          well = (wellDetail[key]);
        } else if (key.split(':') && key.split(':')[1] && well) {
          // eslint-disable-next-line radix
          const id = Number.parseInt(key.split(':')[1]);
          await metaDataModule.postNodeAttribute({ node: well, attributeId: id, val: wellDetail[key] });
        }

        // console.log(newtags);
        if (this.selectedTags && this.selectedTags.length) {
          await scheduleModule.addTagsToWell({ node_id: well, tags: this.selectedTags });
        }

        // console.log(newtags);
        if (this.selectedTags && this.selectedTags.length) {
          await scheduleModule.addTagsToWell({ node_id: well, tags: this.selectedTags });
        }
      });
    });

    this.$emit('finalise-import',data)
    // console.log(this.showWellMenu);
  }

  showModal = false;

   showColumnMenu = false;

  selectedTags: any = [];

  attributeSearchQuery: any = ''

  availableTags: any = []

   showWellMenu = false;

   dataLoading = false;

   showAttributeSearch = false;

   foundSearchResult = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   showSearchPopup = false;

   newCloumnType = '';

   async mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
     const knownAttributes = await metaDataModule.fetchKnownAttributes();
     this.knownAttrbutesList = knownAttributes.map((attibute) => ({ text: attibute.Name, value: attibute.ID }));
     if (knownAttributes && knownAttributes.length) {
       this.knowAttributesData = knownAttributes;
     }

     let tags: any = [];
     const allWellTags = scheduleModule.availableTags;
     //  console.log(allWellTags);
     // console.log(allWellTags)
     if (allWellTags.length) {
       tags = tags.concat(allWellTags);
     }

     if (tags && tags.length) {
       this.availableTags = [...new Set(tags)].sort();
     }
     const wellsList: any = assetsModule.enabledWells;
     this.availableWellNames = wellsList.map((well) => well.wellName);

     //  console.log(this.availableWellNames);
   }

   created() {
     setupDragAndDropSection();
   }

   async closeDialog() {
     this.showModal = false;
   }

   onResize() {
     this.windowWidth = window.innerWidth;
   }
}

function setupNewLayerDropArea() {
  if (stored_zip_file != null) {
        document.getElementById('afterShapefileUploadID') !.style.display = 'grid';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'none';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = stored_zip_file.name;
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = stored_zip_file.size;
        // @ts-ignore
        document.getElementById('fileDragType') !.value = stored_zip_file.type;
  } else {
        document.getElementById('afterShapefileUploadID') !.style.display = 'none';
        document.getElementById('defaultShapefileUploadAreaID') !.style.display = 'block';
        // @ts-ignore
        document.getElementById('fileDragName') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragSize') !.value = '';
        // @ts-ignore
        document.getElementById('fileDragType') !.value = '';
  }
}

let stored_zip_file;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setupDragAndDropSection() {
  sleep(500).then(() => {
    const inputElement = document.getElementById('add_shapefile_input') !;
    inputElement.onchange = function (event) {
      // @ts-ignore
      const fileList = inputElement.files;
      stored_zip_file = fileList[0];
      setupNewLayerDropArea();
      // TODO do something with fileList.
    };
    const holder = document.getElementById('holder');
    // @ts-ignore
    holder.ondragover = function () {
      // @ts-ignore
      this.className = 'hover';
      return false;
    };
    // @ts-ignore
    holder.ondragexit = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragend = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondragleave = function () {
      // @ts-ignore
      this.className = '';
      return false;
    };
    // @ts-ignore
    holder.ondrop = function (e) {
      // @ts-ignore
      this.className = '';
      e.preventDefault();
      // @ts-ignore
      stored_zip_file = e.dataTransfer.files[0];
      setupNewLayerDropArea();
    };
  });
}

